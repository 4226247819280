import React, { useEffect } from 'react';
import DOMPurify from "isomorphic-dompurify";
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import LinkButton from '../../../../elements/linkButton/linkButton.component';
import TickWhite from '../../../../../static/images/tick-circle-white.svg';
import { shouldInsertTooltip } from '../../../../hooks/tooltips/insertTooltip';
import infoIconWhite from '../../../../../static/images/info_outline_white.svg';
import BannerBunnies from '../../../../../static/images/homepage-hero-bunny-pattern.png';
import { routes } from '../../../../shared/constants';

const BannerContainer = styled.div`
  position: relative;
  max-width: 1403px;
  margin: auto;
  padding: 60px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    padding: 30px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    padding: 30px 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 30px;
  }
  
  @media(max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding: 30px 24px;
  } 
`;

const BannerContent = styled.div`
  box-sizing: border-box;
  max-width: 700px;
  color: #ffffff;
  h1, p, li {
    color: #ffffff;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    margin: auto;
    max-width: none;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    padding-top: 0;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) and (max-height: 600px) {
    padding-bottom: 12px;
  }
  
  .nimble-tooltip .info-icon{
    background-image: url('${infoIconWhite}');
  }
`;

const BannerTitle = styled.h1`
  color: ${props => props.theme.colours.slate};
  font-family: ${props => props.theme.font.nimbleFont};
  font-size: 35px;
  line-height: 42px;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 22px;
  text-transform: unset;

  @media(max-width: ${props => props.theme.breakpoints.max.xl}) {
    font-size: 32px;
  } 

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 22px;
    line-height: 26px;
  }  

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) and (max-height: 600px) {
    font-size: 18px;
  }  
`;

const BannerSubtitle = styled.p`
  color: ${props => props.theme.colours.slate};
  font-family: ${props => props.theme.font.nimbleFont};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 22px;
  margin-bottom: 7px;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 16px;
  }
`;

const BannerDescription = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const PointsTitle = styled.p`
  color: ${props => props.theme.colours.slate};
  font-family: ${props => props.theme.font.nimbleFont};
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 25px;
  margin-bottom: 5px;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 16px;
  }
`;

const PointsContainer = styled.ul`
  list-style: none;
  margin-top: 15px;
  max-width: 475px;
`;

const Point = styled.li`
  position: relative;
  font-size: 14px;
  margin-bottom: 7px;
  padding-left: 4px;

  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: -2em;
    width: 20px;
    height: 20px;
    background-image: url('${TickWhite}');
    background-position: center;
    background-size: cover;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin-bottom: 7px;
  }

  @media(min-width: ${props => props.theme.breakpoints.min.lg}) {
    &::before {
      background-image: url('${TickWhite}');
    }
  }
`;

const Banner = styled.div`
  background-color: ${props => props.theme.colours.nimbleRed};
  background-repeat: repeat;
  background-size: 202px 285px;
  background-position: 50% 50%;
  background-image: url(${BannerBunnies});
  ${props => props.background && css`
    position: relative;

    @media(min-width: ${props.theme.breakpoints.min.lg}) {
      ${Point} {
        &::before {
          background-image: url('${TickWhite}');
        }
      }
    }
  `}
`;

const BannerImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    position: relative;
    height: 400px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}) {
    height: 300px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    height: 175px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) and (max-height: 650px) {
    display: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}){
    margin-bottom: 10px;
  }
`;

const BannerImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('${props => props.background}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 1800px) {
    background-position: center 35%;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    ${props => props.mobileBackground && css`
      background-image: url('${props.mobileBackground}');
    `}
  }  
`;

const BannerGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(89.79deg, rgba(169, 169, 169, 0.69) 0.18%, rgba(255, 255, 255, 0) 56.27%);
  mix-blend-mode: multiply;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}) {
    display: none;
  } 
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 8px -8px 24px;

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    display: block;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  min-width: 327px;
  margin: 16px 8px;

  @media ${props => props.theme.mediaBreakpoints.mobile} {
    min-width: 300px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    margin: 16px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    min-width: 275px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    width: 100%;
    min-width: unset;
  }
`;

const StyledSecondaryLink = styled.a`
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  cursor: pointer;
  font-family: ${props => props.theme.font.nimbleFont};
  transition: 0.35s ease;
  text-align: center;

  &:hover {
    color: #ffffff;
    @media (hover:hover) {
      background-color: #ffffff;
      color: ${props => props.theme.colours.nimbleRed};
    }
  }
`;

const AnytimeBanner = ({
  anytimeBannerData,
  onClickApplyNow,
}) => {
  const {
    pointsTitle,
    benefitPoints,
    description,
    title,
    subTitle,
    secondaryLink,
    desktopBackgroundImage,
    mobileBackgroundImage
  } = anytimeBannerData;

  useEffect(() => {
    const isReturner = document.cookie.replace(/(?:(?:^|.*;\s*)isReturner\s*\=\s*([^;]*).*$)|^.*$/, "$1");

    if (isReturner !== "")
      isReturner === "true" ? anytimeBannerData.secondaryLink = `${routes.global.urls.returnerFlow.entry}` : anytimeBannerData.secondaryLink;
  }, []);

  return (
    <Banner background={!!desktopBackgroundImage}>
      {desktopBackgroundImage && (
        <BannerImageWrapper>
          <BannerImage
            background={desktopBackgroundImage ? desktopBackgroundImage.file.url : null}
            mobileBackground={mobileBackgroundImage ? mobileBackgroundImage.file.url : null}
          />
          <BannerGradient />
        </BannerImageWrapper>
      )}
      <BannerContainer>
        <BannerContent>
          {title && <BannerTitle dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />}
          {subTitle && <BannerSubtitle>{subTitle}</BannerSubtitle>}
          {description && <BannerDescription>{description}</BannerDescription>}
          <ButtonsWrapper>
            <ButtonContainer>
              <LinkButton
                text="Apply now - AnyTime"
                color="GREEN"
                onclick={onClickApplyNow}
              />
            </ButtonContainer>
            {secondaryLink && (
              <ButtonContainer secondary>
                <StyledSecondaryLink href={secondaryLink}>
                  Apply now - Cash Loan
                </StyledSecondaryLink>
              </ButtonContainer>
            )}
          </ButtonsWrapper>
          {pointsTitle && <PointsTitle>{pointsTitle}</PointsTitle>}
          <PointsContainer>
            {benefitPoints && (
              benefitPoints.map((point) => {
                return (
                  <Point key={point}>{shouldInsertTooltip(point)}</Point>
                );
              })
            )}
          </PointsContainer>
        </BannerContent>
      </BannerContainer>
    </Banner>
  );
};
AnytimeBanner.propTypes = {
  anytimeBannerData: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    secondaryLink: PropTypes.string,
    pointsTitle: PropTypes.string,
    benefitPoints: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    desktopBackgroundImage: PropTypes.shape({
      file: PropTypes.objectOf(PropTypes.string)
    }),
    mobileBackgroundImage: PropTypes.shape({
      file: PropTypes.objectOf(PropTypes.string)
    })
  })
};
export default AnytimeBanner;
